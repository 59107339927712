/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #dce5e2;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aaaaaa;
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #0a7952;
}


::-webkit-scrollbar-corner {
  background-color: #dce5e2;
}

body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
}

.wrapper {
  background-color: #ffffff;
  display: flex;
  align-items: stretch;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  padding: 0;
  flex: auto;
  display: flex;
  overflow: auto;
  /*  position: relative; */
}

.page-container {
  padding: 0;
  margin-left: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
}

.flex-column-layout {
  display: flex;
  flex-direction: column;
}

.position-relative {
    position: relative;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Sizing and spacing */

.min-w-25pct {
  min-width: 25%;
}

.min-w-50pct {
  min-width: 50%;
}

.min-w-5 {
  min-width: 5rem;
}

.no-margin {
  margin: 0;
}

.m-05 {
  margin: 0.5rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-025 {
  margin-bottom: 0.25rem;
}

.pe-025 {
  margin-right: 0.25rem;
}

.m-0125 {
  margin: 0.125rem;
}

.mx-0125 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.p-05 {
  padding: 0.5rem;
}

.pe-025 {
  padding-right: 0.25rem;
}

.p-1 {
  padding: 1rem;
}

.pb-05, .py-05 {
  padding-bottom: .1rem !important;
}

.pt-05, .py-05 {
  padding-top: .1rem !important;
}


.h-38 {
  height: 38px;
}

/* Global Kendo UI component styles */

.k-input-solid {
  background-color: #fff;
}

.k-dialog {
  width: 70%;
  max-height: 90%;
}

.k-dialog-buttongroup {
  display: flex;
  justify-content: flex-end;
}

.k-list-scroller .k-list .k-item:hover {
  color: #16181b;
  background-color: #dce5e2;
}

/* KendoCombobox */
.k-combobox .k-dropdown-wrap {
  background-color: #ffffff;
}

/* KendoDropdownList */
.k-dropdownlist {
  background-color: #dce5e2;
}

  .k-dropdownlist:hover {
    border-color: #bfcfca;
    background-color: #c6d5d0;
  }
/* KendoUpload */
.k-upload-button {
  background-color: #dce5e2;
  border: 1px solid #dce5e2;
}


/* TabPanel */
.grid-container.k-tabstrip > .k-content.k-active {
  display: flex;
}

.k-tabstrip-items-wrapper {
  border-color: transparent;
}

.k-pager-sizes .k-dropdownlist,
.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  background-color: #edf2f0;
}

  .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip > .k-content {
    background-color: #ccd8d5;
  }

/* KendoGrid */
.k-grid tr.clickable {
  cursor: pointer;
}

.k-grid-header,
.k-grid-pager {
  background-color: #dce5e2;
}

/* KendoGrid - compact view */
.k-grid th, .k-grid td {
  padding: .25rem .5rem;
}

/* KendoGrid - row coloring */
.k-grid tr.status-warning {
  color: #856404;
  background-color: #fff3cd;
}

.k-grid tr.status-alert {
  color: #721c24;
  background-color: #f8d7da;
}

.k-grid tr.invalid-suggestion {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.k-grid tr.unverified-suggestion {
  color: #856404;
  background-color: #fff3cd;
  border-color: #dccea3;
}

.toast-success {
  background-color: #0a7952;
}

.toast-error {
  background-color: #cf3917;
}

.k-panelbar > .k-panelbar-item > .k-link {
  border-color: #05402c;
  color: #fff;
  background-color: #0a7952;
  padding: 0.55rem 0.75rem
}

  .k-panelbar > .k-panelbar-item > .k-link:hover,
  .k-panelbar > .k-panelbar-item > .k-link.k-state-hover {
    background-color: #408560;
  }

.k-panelbar > .k-panelbar-item.k-panelbar-header > .k-link.k-selected:hover {
  background-color: #0a7952;
}

.k-panelbar > .k-panelbar-item > .k-link .k-icon {
  color: #fff;
}

.form-group {
  margin-bottom: 0.25rem;
}
  /* Required asterix */
  label.required:after {
    padding-left: 0.25rem;
    content: "*";
    color: red;
  }

.required-for-save {
  position: relative;
}

  .required-for-save:after {
    position: absolute;
    right: 0;
    padding-left: 0.25rem;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 0.75rem;
    text-rendering: auto;
    vertical-align: text-top;
    content: "\f0c7";
    color: #dc3545;
  }

.required-for-upgrade {
  position: relative;
}

  .required-for-upgrade:after {
    position: absolute;
    right: 0;
    padding-left: 0.25rem;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 0.75rem;
    text-rendering: auto;
    vertical-align: text-top;
    content: "\f04e";
    color: #dc3545;
  }

.alert {
  padding: .1rem .75rem;
  margin-bottom: 0;
  font-size: 14px;
}

/* Card */
.info-card {
  border-color: #dee2e6;
  background-color: #dce5e2;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  padding: .375rem .75rem;
  flex: 1;
}  

  .info-card-light {
    border-color: #dee2e6;
    border-width: 1px;
    border-style: solid;
    background-color: rgba(255,255,255,0.7);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    flex: 1;
  }

/* Validation styles */
.k-autocomplete.k-header.ng-invalid.ng-touched,
.k-datepicker.ng-invalid.ng-touched .k-dateinput-wrap,
.k-datepicker.ng-invalid.ng-touched .k-picker-wrap,
.k-datepicker.ng-invalid.ng-dirty .k-dateinput-wrap,
.k-datepicker.ng-invalid.ng-dirty .k-picker-wrap,
.k-numerictextbox.ng-invalid.ng-touched .k-numeric-wrap,
.k-dropdown.ng-invalid.ng-touched > .k-dropdown-wrap,
input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
.alert.ng-invalid,
.stamps.ng-invalid {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #dc3545;
}

  input.ng-invalid.ng-touched::placeholder,
  textarea.ng-invalid.ng-touched::placeholder,
  .k-autocomplete.k-header.ng-invalid.ng-touched input::placeholder,
  .k-numerictextbox.ng-invalid.ng-touched input::placeholder {
    color: #721c24;
  }

/* highlight elements */
.highlighted-element {
  animation: highlight 1000ms ease-out;
}

@keyframes highlight {
  0% {
    background-color: #d3eee5;
    border-color: #05402c;
    box-shadow: 0px 0px 0.375rem #05402c;
  }

  100% {
    background-color: none;
    border-color: none;
    box-shadow: none;
  }
}

.highlighted-invalid-element {
  animation: highlight-invalid 1000ms ease-out;
}

@keyframes highlight-invalid {
  0% {
    box-shadow: 0px 0px 0.375rem #dc3545;
  }

  100% {
    box-shadow: none;
  }
}

.k-tabstrip > .k-tabstrip-items > .k-state-active {
  background-color: rgba(0, 61, 44, 0.2);
}

.k-tabstrip .k-state-active {
  background-color: rgba(0, 61, 44, 0.2);
}

.k-tabstrip .k-tabstrip-items {
  display: block;
}

.k-tabstrip-items .k-item {
  display: inline-block;
}

.k-popup .k-outer-group-header {
  background-color: #dee2e6;
}
